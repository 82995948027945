import React, { useState } from 'react';
import LandingPage from './LandingPage';
import CountingApp from './CountingApp';

function App() {
  const [showCountingApp, setShowCountingApp] = useState(false);

  if (showCountingApp) {
    return <CountingApp onGoBack={() => setShowCountingApp(false)} />;
  }

  return <LandingPage onStartLearning={() => setShowCountingApp(true)} />;
}

export default App;
