import React, { useState } from 'react';

const numbers = [
  { english: 'One', tagalog: 'Isa', phonetic: 'ee-sah' },
  { english: 'Two', tagalog: 'Dalawa', phonetic: 'dah-lah-wah' },
  { english: 'Three', tagalog: 'Tatlo', phonetic: 'taht-loh' },
  { english: 'Four', tagalog: 'Apat', phonetic: 'ah-paht' },
  { english: 'Five', tagalog: 'Lima', phonetic: 'lee-mah' },
  { english: 'Six', tagalog: 'Anim', phonetic: 'ah-neem' },
  { english: 'Seven', tagalog: 'Pito', phonetic: 'pee-toh' },
  { english: 'Eight', tagalog: 'Walo', phonetic: 'wah-loh' },
  { english: 'Nine', tagalog: 'Siyam', phonetic: 'see-yahm' },
  { english: 'Ten', tagalog: 'Sampu', phonetic: 'sahm-poo' },
];

function CountingApp({ onGoBack }) {
  const [currentNumber, setCurrentNumber] = useState(0);

  const handleNext = () => {
    setCurrentNumber((prev) => (prev + 1) % numbers.length);
  };

  const handlePrevious = () => {
    setCurrentNumber((prev) => (prev - 1 + numbers.length) % numbers.length);
  };

  return (
    <div className="App" style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      fontFamily: 'Arial, sans-serif',
      background: 'linear-gradient(to right, #667eea, #764ba2)',
      color: 'white',
      position: 'relative'
    }}>
      <button onClick={onGoBack} style={{
        position: 'absolute',
        top: '20px',
        left: '20px',
        ...buttonStyle,
        background: '#3949AB'
      }}>
        Go Back
      </button>
      <div style={{
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: '15px',
        padding: '2rem',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}>
        <h1 style={{ fontSize: '2.5rem', marginBottom: '1rem' }}>Let's Count to 10!</h1>
        <div style={{ fontSize: '5rem', fontWeight: 'bold', marginBottom: '1rem' }}>{currentNumber + 1}</div>
        <div style={{ fontSize: '2rem', marginBottom: '0.5rem' }}>{numbers[currentNumber].english}</div>
        <div style={{ fontSize: '2rem', marginBottom: '0.5rem' }}>{numbers[currentNumber].tagalog}</div>
        <div style={{ fontSize: '1.5rem', fontStyle: 'italic', marginBottom: '1rem' }}>/{numbers[currentNumber].phonetic}/</div>
        <div>
          <button onClick={handlePrevious} disabled={currentNumber === 0} style={buttonStyle}>Previous</button>
          <button onClick={handleNext} disabled={currentNumber === numbers.length - 1} style={buttonStyle}>Next</button>
        </div>
      </div>
    </div>
  );
}

const buttonStyle = {
  fontSize: '1rem',
  padding: '0.5rem 1rem',
  margin: '0 0.5rem',
  background: '#4CAF50',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer'
};

export default CountingApp;
