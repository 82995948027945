import React from 'react';

const LandingPage = ({ onStartLearning }) => {
  const styles = {
    container: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif',
      background: 'linear-gradient(to right, #4e54c8, #8f94fb)',
      color: 'white',
    },
    imageContainer: {
      marginBottom: '20px',
    },
    image: {
      width: '300px',  // Adjust this value as needed
      height: 'auto',
    },
    title: {
      fontSize: '2.5rem',
      marginBottom: '10px',
    },
    subtitle: {
      fontSize: '1.5rem',
      marginBottom: '30px',
    },
    card: {
      background: 'white',
      borderRadius: '8px',
      padding: '20px',
      maxWidth: '400px',
      width: '100%',
      color: '#333',
    },
    cardTitle: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    description: {
      fontSize: '1rem',
      marginBottom: '20px',
    },
    button: {
      background: '#4CAF50',
      color: 'white',
      border: 'none',
      padding: '10px 20px',
      fontSize: '1rem',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.imageContainer}>
        <img 
          src="/cartoon_lucas.jpg" 
          alt="Learn with Lucas Logo" 
          style={styles.image}
        />
      </div> 
      <h1 style={styles.title}>Learn Tagalog with Lucas</h1>
      <h2 style={styles.subtitle}>Matuto ng tagalog kay Lucas</h2>
      <div style={styles.card}>
        <h3 style={styles.cardTitle}>Featured App: Counting to 10</h3>
        <p style={styles.description}>
          Learn to count from one to ten in Tagalog with pronunciation guide.
        </p>
        <button style={styles.button} onClick={onStartLearning}>
          Start Learning Now
        </button>
      </div>
    </div>
  );
};

export default LandingPage;
